<template>
    <div>
        <input type="file" id="fileInput" ref="fileInput" v-on:change="performFileUpload();" style="display: none">
        <v-btn :loading="isUploading"
               color="blue"
               class="ma-1"
               @click="displayUploadDialog();">
            Upload
            <v-icon right
                    dark>
                mdi-cloud-upload
            </v-icon>
        </v-btn>
        <span v-if="uploadResult !== null && uploadResult.success" class="ml-2" style="font-weight:bold; font-size:small;">Successful <font-awesome-icon :icon="['fas', 'check']" color="green" class="ml-2" /></span>
        <span v-if="uploadResult !== null && !uploadResult.success" class="ml-2" style="font-weight:bold; font-size:small;">Failed <font-awesome-icon :icon="['fas', 'times']" color="red" class="ml-2" /></span>
    </div>
</template>

<script>

    import { fileService } from '../services/file-service';
    import { serviceHelper } from '../shared/service-helper';

    export default {
        name: 'TaxFileUploader',

        /* PROPS */
        props: {
            TaxYear: {
                type: String,
                default: () => '',
            },
            Exchange: {
                type: String,
                default: () => '',
            },
            TaxMethod: {
                type: String,
                default: () => '',
            }
        },

        /* COMPONENT DATA  */
        data() {
            return {
                file: null,
                isUploading: false,
                uploadResult: null,
            };
        },

        /* COMPONENT METHODS  */
        methods: {

            async displayUploadDialog() {
                this.$emit('uploadOpen', true);
                this.file = null;
                this.uploadResult = null;
                this.$refs.fileInput.click();
            },

            async performFileUpload() {
                if (this.$refs.fileInput.files.length !== 0) {
                    try {
                        this.isUploading = true;
                        await serviceHelper.simulateAwait(true, 500);
                        this.file = this.$refs.fileInput.files[0];
                        var contents = await this.file.text();
                        contents = btoa(contents);
                        var uploadArgs =
                        {
                            fileContent: contents,
                            taxYear: this.TaxYear,
                            taxMethod: this.TaxMethod,
                            exchange: this.Exchange
                        };

                        this.uploadResult = await fileService.uploadFileToServer(this, uploadArgs);
                        this.isUploading = false;
                        if (this.uploadResult.success) {
                            this.$emit('uploadSuccess', this.uploadResult.value, contents);
                        }
                        else {
                            this.$emit('uploadFail', this.uploadResult.error);
                        }
                    }
                    catch {
                        this.isUploading = false;
                        this.uploadResult = { success: false }
                        this.$emit('uploadFail', "The file you selected could not be uploaded.");
                    }
                    finally {
                        //Reset the file to empty after the upload
                        if (this.$refs.fileInput) {
                            this.$refs.fileInput.value = '';
                        }
                    }
                }
                else {
                    //Dialog was canceled
                }
            },

            /* PUBLIC METHODS  */
            ClearUpload() {
                //Reset the file to empty after the upload
                if (this.$refs.fileInput) {
                    this.$refs.fileInput.value = '';
                }
                this.uploadResult = null;
            }
        },
    };
</script>